@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300&display=swap);
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: 'Noto Sans JP', sans-serif!important;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  margin: 0;
  padding: 0;
}
ul,
ol {
  list-style: none;
}
body {
  word-wrap: break-word;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.templates-wrapper {
  min-height: 1000px;
}
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.home-page-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.home-page-wrapper .home-page {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.home-page-wrapper .title-wrapper > h1,
.home-page-wrapper > h1 {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 16px;
}
.home-page-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
.home-page {
  padding: 128px 24px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper .home-page {
    padding: 56px 24px;
  }
  .home-page-wrapper .home-page > h1 {
    font-size: 24px;
    margin: 0 auto 32px;
  }
  .home-page-wrapper .home-page > h1.title-h1 {
    margin-bottom: 8px;
  }
  .home-page-wrapper .home-page > p {
    margin-bottom: 32px;
  }
}
.header0 {
  background: rgba(64, 64, 64, 0.1);
  width: 100%;
  z-index: 1;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
  position: relative;
  top: 0;
}
.header0 .home-page {
  padding: 0 1%;
  max-width: 100%;
}
.header0-logo-page {
  width: 500px;
  float: left;
}
.header0-logo {
  display: inline-block;
  position: relative;
  line-height: 96px;
}
.header0-logo img {
  vertical-align: middle;
  display: inline-block;
}
.header0-logo a {
  display: block;
}
.header0-title h1 {
  color: #ffffff;
  font-size: 20px;
}
.header0-menu {
  float: right;
}
.header0-menu > .ant-menu {
  font-size: 16px;
  line-height: 96px;
  background: transparent;
  color: #fff;
  height: 98px;
  border-bottom-color: transparent;
  position: relative;
}
.header0-menu > .ant-menu a:hover {
  color: #1DA57A;
}
.header0-menu > .ant-menu .ant-menu-item.ant-menu-item-selected.TrackRecord {
  cursor: pointer;
  pointer-events: none;
}
.header0-menu > .ant-menu .disabled {
  pointer-events: none;
}
.header0 .ant-menu-item {
  padding: 0 10px;
}
.header0 .ant-menu-item-selected a {
  color: #1DA57A;
}
@media screen and (max-width: 900px) {
  .header0-title h1 {
    font-size: 16px;
  }
  .header0-logo-page {
    width: 300px;
  }
  .header0-logo {
    z-index: 101;
  }
  .header0 .header0-menu {
    height: auto;
    float: inherit;
    position: relative;
    left: -24px;
    width: calc(100% + 48px);
    opacity: 0;
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header0 .header0-menu li {
    padding: 0 24px;
  }
  .header0 .header0-menu li.ant-menu-submenu {
    padding: 0;
  }
  .header0 .header0-menu .ant-menu-submenu .ant-menu-sub {
    padding: 0 24px;
  }
  .header0-mobile-menu {
    width: 24px;
    height: 14px;
    cursor: pointer;
    position: absolute;
    top: 42px;
    right: 32px;
    z-index: 100;
  }
  .header0-mobile-menu em {
    display: block;
    width: 100%;
    height: 2px;
    background: rgba(0, 0, 0, 0.65);
    margin-top: 4px;
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header0-mobile-menu :first-child {
    margin-top: 0;
  }
  .header0 .ant-menu {
    height: auto;
    overflow: hidden;
    background: rgba(64, 64, 64, 0.1);
  }
  .header0 .ant-menu .ant-menu-item-selected {
    border: none;
  }
  .header0 .ant-menu a {
    color: rgba(0, 0, 0, 0.65);
    padding-left: 17px;
  }
  .header0 .ant-menu a:hover {
    color: rgba(0, 0, 0, 0.65);
  }
  .header0 .ant-menu .ant-menu-item-selected a {
    color: rgba(0, 0, 0, 0.65);
  }
  .header0 .open {
    height: auto;
  }
  .header0 .open .header0-mobile-menu em:nth-child(1) {
    transform: translateY(6px) rotate(45deg);
  }
  .header0 .open .header0-mobile-menu em:nth-child(2) {
    opacity: 0;
  }
  .header0 .open .header0-mobile-menu em:nth-child(3) {
    transform: translateY(-6px) rotate(-45deg);
  }
  .header0 .open > .header0-menu {
    opacity: 1;
    pointer-events: auto;
  }
}
.footer0-wrapper {
  background-color: rgba(64, 64, 64, 0.1);
  height: 80px;
  overflow: hidden;
  box-shadow: 0 -5px 8px rgba(0, 0, 0, 0.15);
}
.footer0-wrapper .footer0 {
  height: 100%;
  padding: 0 24px;
  line-height: 80px;
  text-align: center;
  position: relative;
}
@media screen and (max-width: 767px) {
  .footer0-wrapper .footer0 {
    font-size: 12px;
  }
  .footer0-wrapper .footer0.home-page {
    padding: 0;
  }
  .footer0-wrapper .footer0 > div {
    width: 90%;
    margin: auto;
  }
}
.banner2 {
  width: 100%;
  height: calc(100vh - 98px);
  position: relative;
  border-color: #666;
  background: #fff;
}
.banner2 .banner-anim {
  height: 100%;
}
.banner2 .queue-anim-leaving {
  position: relative !important;
  width: auto;
}
.banner2 .banner-user-elem {
  height: 100%;
  color: #fff;
  position: relative;
  overflow: hidden;
}
.banner2 .bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}
.banner2 .bg0 {
  background: url(../../static/media/Banner-1.4414e493.jpg) center;
  background-size: cover;
  height: 100%;
  width: calc(100% + 100px);
  left: -50px;
}
.banner2 .bg0:before {
  width: 100%;
  height: 100%;
  content: '';
  background: rgba(0, 0, 0, 0.35);
  display: block;
}
.banner2 .bg1 {
  background: url(../../static/media/Banner-2.8def0ff0.jpg) center;
  background-size: cover;
  height: 100%;
  width: calc(100% + 100px);
  left: -50px;
}
.banner2 .bg1:before {
  width: 100%;
  height: 100%;
  content: '';
  background: rgba(0, 0, 0, 0.35);
  display: block;
}
.banner2 .bg2 {
  background: url(../../static/media/Banner-3.54064996.jpg) center;
  background-size: cover;
  height: 100%;
  width: calc(100% + 100px);
  left: -50px;
}
.banner2 .bg2:before {
  width: 100%;
  height: 100%;
  content: '';
  background: rgba(0, 0, 0, 0.35);
  display: block;
}
.banner2 .bg3 {
  background: url(../../static/media/Banner-4.e81a7eb2.jpg) center;
  background-size: cover;
  height: 100%;
  width: calc(100% + 100px);
  left: -50px;
}
.banner2 .bg3:before {
  width: 100%;
  height: 100%;
  content: '';
  background: rgba(0, 0, 0, 0.35);
  display: block;
}
.banner2 .page1 {
  position: relative;
  top: 50%;
  right: -10%;
  text-align: left;
}
.banner2 .page2 {
  position: relative;
  top: 50%;
  right: -35%;
  text-align: right;
}
.banner2 .banner-user-elem .banner-user-title {
  font-size: 22px;
  top: 40%;
}
.banner2 .banner-user-elem .banner-user-text {
  top: 40%;
}
.banner2-text-wrapper {
  display: inline-block;
  font-size: 14px;
  color: #fff;
  width: 620px;
}
.banner2-text-wrapper .banner2-title {
  left: 0px;
  margin: auto;
  position: relative;
  font-size: 48px;
}
.banner2-text-wrapper .banner2-content {
  word-wrap: break-word;
  margin: auto auto 20px;
  font-size: 20px;
}
.banner2-text-wrapper button {
  border: 1px solid #fff;
  color: #fff;
  background: transparent;
  transition: background 0.45s cubic-bezier(0.55, 0.055, 0.675, 0.19), border 0.45s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  line-height: 36px;
  font-size: 16px;
  height: 36px;
}
.banner2-text-wrapper button span {
  text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  transition: text-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.banner2-text-wrapper button:hover {
  color: #fff;
  border-color: #1DA57A;
  background: #1DA57A;
  transition: background 0.45s cubic-bezier(0.215, 0.61, 0.355, 1), border 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.banner2-text-wrapper button:hover span {
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.35);
}
.banner2-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  z-index: 10;
  left: 50%;
  margin-left: -12px;
  color: #fff;
}
.banner-anim-thumb-default span {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
}
@media (max-width: 480px) {
  .banner2 .bg {
    height: 85%;
  }
  .banner2-icon {
    bottom: 140px !important;
  }
  .banner-anim-thumb {
    bottom: 100px;
  }
  .banner-anim-arrow-default {
    top: 40%;
  }
}
@media screen and (max-width: 700px) {
  .banner2 .page1 {
    top: 27%;
  }
  .banner2-page {
    right: 0%;
  }
  .banner2-text-wrapper {
    width: 300px;
    left: 0;
    margin: auto;
    padding: 0;
    text-align: center;
    display: block;
  }
  .banner2-text-wrapper .logo {
    width: 90%;
    left: 0;
  }
  .banner2-text-wrapper .banner2-title {
    text-align: center;
    font-size: 32px;
  }
  .banner2-text-wrapper .banner2-content {
    text-align: center;
    font-size: 22px;
  }
}
.content0-wrapper {
  min-height: 400px;
  overflow: hidden;
}
.content0-wrapper .content0 {
  overflow: hidden;
  height: 100%;
  padding: 64px 24px;
  text-align: center;
}
.content0-wrapper .content0 > .title-wrapper {
  margin: 0 auto 48px;
}
.content0-wrapper .content0 .block-wrapper {
  position: relative;
  height: 100%;
  overflow: hidden;
  top: 25%;
  padding: 20px 0;
}
.content0-wrapper .content0 .block-wrapper .block {
  padding: 0 4%;
  display: inline-block;
  text-align: center;
  height: 200px;
  margin-bottom: 48px;
}
.content0-wrapper .content0 .block-wrapper .block.queue-anim-leaving {
  position: relative !important;
}
.content0-wrapper .content0 .block-wrapper .block .icon {
  width: 100px;
  height: 100px;
  margin: auto;
  display: flex;
  align-items: center;
}
.content0-wrapper .content0 .block-wrapper .block .content0-title {
  line-height: 32px;
  margin: 10px auto;
}
.content0-wrapper .content0 .block-wrapper .block .content0-text {
  font-size: 16px;
}
@media screen and (max-width: 767px) {
  .content0-wrapper {
    height: 880px;
  }
}
.content00-wrapper {
  min-height: 800px;
  padding-top: 50px;
}
.content00-wrapper .content00 {
  height: 100%;
  padding: 0 24px;
}
.content00-wrapper .content00-title-wrapper {
  margin: 0 0 -70px;
  overflow: hidden;
  padding: 24px 24px;
  margin: 0 auto 48px;
}
.content00-wrapper .content00-img {
  height: 100%;
  transform-origin: top;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content00-wrapper .content00-img span {
  display: block;
  width: 250px;
}
.content00-wrapper .content00-img span img {
  display: block;
}
.content00-wrapper .content00-text {
  padding: 0 32px;
  height: 100%;
}
.content00-wrapper .content00-text .content00-content {
  font-size: 18px;
}
.content00-wrapper .content00-text .content00-title {
  position: relative !important;
  font-size: 32px;
  font-weight: normal;
  color: #404040;
  margin-top: 60px;
}
.content00-wrapper .content00-text .content00-content {
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .content00-wrapper .content00-img {
    height: 200px;
    padding: 0;
    text-align: center;
    margin-top: 64px;
  }
  .content00-wrapper .content00-img span {
    display: inline-block;
    width: 180px;
    height: 200px;
    line-height: 200px;
    margin: auto;
  }
  .content00-wrapper .content00-text {
    height: auto;
    margin-bottom: 20px;
    text-align: center;
    padding: 0;
  }
  .content00-wrapper .content00-text .content00-content,
  .content00-wrapper .content00-text .content00-title {
    width: 100%;
    top: auto;
  }
  .content00-wrapper .content00-text .content00-title {
    margin: 32px auto 16px;
    font-size: 24px;
  }
}
.content3-wrapper {
  min-height: 630px;
}
.content3-wrapper .content3 {
  height: 100%;
  overflow: hidden;
  text-align: center;
}
.content3-wrapper .content3 .title-content {
  text-align: center;
}
.content3-wrapper .content3-block-wrapper {
  position: relative;
}
.content3-wrapper .content3-block-wrapper .content3-block {
  display: inline-block;
  padding: 22px 22px;
  vertical-align: top;
}
.content3-wrapper .content3-block-wrapper .content3-block .content3-icon {
  display: inline-block;
  width: 15%;
  vertical-align: top;
}
.content3-wrapper .content3-block-wrapper .content3-block .content3-text {
  font-size: 16px;
  width: 85%;
  display: inline-block;
  padding-left: 8%;
  text-align: left;
}
.content3-wrapper .content3-block-wrapper .content3-block.clear-both {
  clear: both;
}
@media screen and (max-width: 767px) {
  .content3-wrapper .content3-block-wrapper {
    margin: 20px auto;
    height: auto;
  }
  .content3-wrapper .content3-block-wrapper .content3-block .content3-title {
    font-size: 20px;
  }
  .content3-wrapper .content3-block-wrapper .content3-block.queue-anim-leaving {
    position: relative !important;
  }
}

.ContactUs-wrapper {
  overflow: hidden;
}
.ContactUs-wrapper .ContactUs {
  overflow: hidden;
  height: 100%;
  padding: 24px 24px;
}
.ContactUs-wrapper .ContactUs-title {
  height: 200px;
}
.ContactUs-wrapper .ContactUs-title-background {
  background: url(../../static/media/Banner-4.e81a7eb2.jpg) center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0% 23%;
}
.ContactUs-wrapper .ContactUs-title-text {
  background-color: rgba(255, 255, 255, 0.3);
  height: 200px;
  padding-top: 75px;
  text-align: center;
}
.ContactUs-wrapper .ContactUs-textWrapper {
  margin: 30px 10%;
}
.ContactUs-wrapper .ContactUs-textWrapper-introduction {
  font-size: 18px;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .ContactUs {
    height: 880px;
  }
  .ContactUs .ContactUs-textWrapper-introduction {
    font-size: 16px;
  }
}

.TrackRecord-wrapper {
  overflow: hidden;
}
.TrackRecord-wrapper .TrackRecord {
  overflow: hidden;
  height: 100%;
  padding: 24px 24px;
}
.TrackRecord-wrapper .TrackRecord-title {
  height: 200px;
}
.TrackRecord-wrapper .TrackRecord-title-background {
  background: url(../../static/media/Banner-1.4414e493.jpg) center;
  background-size: cover;
  background-repeat: no-repeat;
}
.TrackRecord-wrapper .TrackRecord-title-text {
  background-color: rgba(255, 255, 255, 0.3);
  height: 200px;
  padding-top: 75px;
  text-align: center;
}
.TrackRecord-wrapper .TrackRecord-content-wrapper {
  margin: 70px 10%;
  font-weight: normal;
  letter-spacing: 0.5px;
}
.TrackRecord-wrapper .TrackRecord-content-wrapper-title {
  font-size: 24px;
  margin-bottom: 10px;
}
.TrackRecord-wrapper .TrackRecord-content-wrapper-littleTitle {
  font-size: 18px;
  text-align: justify;
  margin-bottom: 5px;
}
.TrackRecord-wrapper .TrackRecord-content-wrapper-text {
  text-align: justify;
  font-size: 16px;
  text-indent: 36px;
}
@media screen and (max-width: 767px) {
  .TrackRecord {
    height: 880px;
  }
  .TrackRecord .TrackRecord-content-wrapper-littleTitle {
    text-indent: 0px;
  }
  .TrackRecord .TrackRecord-content-wrapper-text {
    text-indent: 0px;
  }
}

